export default {
  dataModel: {
    types: ['capability'],
    list: {
      containers: {
        pod: ['/capabilities']
      },
      fetchContainer: true
    }
  }
};
